var CollapseManager = function () {
    "use strict";

    var self = this;

    this.initialize = function () {
        this.initializeCollapse();
        this.eventDelegation();
    };

    this.initializeCollapse = function () {
        if(myApp.deviceDetector.isXsScreen()) {
            $.each($('[data-toggle="collapse-xs"]'), function () {
                $(this).attr('data-toggle', 'collapse');
            });
        }
    };

    this.eventDelegation = function () {
        $('.collapse-show-details').on('show.bs.collapse', function () {
            myApp.domAnimator.fadeInHiddenElement($('#see-more-details-' + $(this).attr('data-id')).attr('data-elemTarget'), $('#see-more-details-' + $(this).attr('data-id')).attr('data-elemNonTarget'));
            var elementToCollapse = $($('#see-more-details-' + $(this).attr('data-id')).attr('data-parent')).find('.collapse-show-details.collapse.in').not($(this));
            elementToCollapse.collapse('hide');
            myApp.textTransformer.changeText($('#see-more-details-' + elementToCollapse.attr('data-id')).find('[data-see-more-details-text]'));
        });
        $('body').on('hide.bs.collapse', '.collapse', function () {
            $(this).siblings('.collapse-head').removeClass('active');
            self.scrollToElementCollapse(this);
        });
        $('body').on('show.bs.collapse', '.collapse', function () {
            $(this).siblings('.collapse-head').addClass('active');
        });
        $('body').on('shown.bs.collapse hide.bs.collapse','.info-group-collapse', function () {
            var icon = $(this).prev().find('[data-entity=icon]');
            self.changeCollapseIcon(icon, icon.attr('data-currentclass'), icon.attr('data-futureclass'));
        });
        $('.collapse-exclusive').on('click', function (event) {
            self.exclusiveCollapse(this, event);
        });
        $(".collapse").on('show.bs.collapse hide.bs.collapse', function () {
            if ($(this).prev("[data-toggle-class]")) {
                var toggleClasses = $(this).prev("[data-toggle-class]").data('toggle-class');
                $(this).prev("[data-toggle-class]").toggleClass(toggleClasses);
            }            
        });

        $('body').on('click', '.collapse-toggle:not(.collapsed) [data-scrollto]', (event) => {
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
        })

        $(window).on("resize", function (event) {
            self.initializeCollapse();
        });
    };
    this.changeCollapseIcon = function (elem, currentClass, futureClass) {
        elem.removeClass(currentClass);
        elem.addClass(futureClass);
        elem.attr('data-currentClass', futureClass);
        elem.attr('data-futureClass', currentClass);
    };

    this.unidirectionalCollapse = function (collapse) {
        if (collapse.length > 0 && !$(collapse).hasClass('in')) {
            $(collapse).collapse('show');
        }
    };

    this.toggleElement = function (elem) {
        if (elem.is(':visible')) {
            elem.hide();
        } else {
            elem.show();
        }
    };

    this.exclusiveCollapse = function (collapse, event) {
        event.preventDefault();
        myApp.domManager.toggleClass($(collapse), $('[data-parent="' + $(collapse).data('parent') + '"][data-toggle="collapse"]'), 'active');
        if (!$($(collapse).data('target')).hasClass('in')) {
            $($(collapse).data('parent')).find('.collapse.in').each(function (index) {
                $(this).removeClass('in');
            });
        } else {
            $(collapse).removeClass('active');
        }
    };
    
    this.scrollToElementCollapse = function (elem) {
        var scrollTo = $(elem).data('scroll-to');
        var currentElement = $(elem).parents(scrollTo);
        if (typeof scrollTo !== 'undefined') {
            myApp.scrollManager.scrollToElement(currentElement);
        }
    };
    this.initialize();
};

export default CollapseManager;
