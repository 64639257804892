import DataLayerGenerator from "./dataLayerGenerator";
import DeviceDetector from "@web/vpt-assets/assets/js/utilities/deviceDetector";

export default class LoginDataLayerGenerator {
    constructor() {
        this.dataLayerGenerator = new DataLayerGenerator();
        this.deviceDetector = new DeviceDetector()
        this.generateLoginDataLayerData();
    }

    generateLoginDataLayerData() {

        const cookieUserGA = this.getGACookie();
        const userId = this.getUserIdCookie() ? this.getUserIdCookie() : null;
        const userStatus = userId ? "logged" : "unlogged";
        const cookieUserType = userId ? this.getUserTypeTrackingTextCookie() : null;
        const language = myApp.config.locale;
        const userCountry = myApp.config.userCountry;
        const isSubscribedToNewsletter = myApp.config.user?.isNewsletterSubscribed;
        const device = this.deviceDetector.isIPad() ? 'tablet' : myApp.config.deviceType;
        const browser = this.deviceDetector.detectBrowser();
        const source = myApp.urlManager.getUrlParam('utm_source');
        const tags = this.getFormattedTags(myApp.config.user?.tags);

        this.sendEvent(cookieUserGA, userId, userStatus, cookieUserType, language, userCountry, isSubscribedToNewsletter, device, browser, source, tags);
    }

    getGACookie(){
        let cookieUserGA = myApp.browserStorageService.getCookie('_ga');
        return cookieUserGA.replace("GA1.2.", "");
    }

    getUserIdCookie(){
        return myApp.browserStorageService.getCookie('usuario_web_id');
    }

    getUserTypeTrackingTextCookie(){
        const cookieUserTypeTrackingText = myApp.browserStorageService.getCookie('userTypeTrackingText');
        return decodeURIComponent(cookieUserTypeTrackingText).replace(/[+]/g, ' ');
    }

    sendEvent(cookieUserGA, userId, userStatus, cookieUserType, language, userCountry, isSubscribedToNewsletter, device, browser, source, tags){
        let datalayer = {
            event: "user_info",
            user_ga: cookieUserGA,
            user_id: userId,
            user_status: userStatus,
            user_type: cookieUserType,
            language: language,
            user_location: userCountry,
            is_subscribed_to_newsletter: isSubscribedToNewsletter,
            device: device,
            browser: browser,
            source: source,
            interests: tags
        }

        this.dataLayerGenerator.generate(datalayer);
    }

    getFormattedTags(tags = []) {
        let formattedTags = []
        tags.forEach(tag => {
            formattedTags.push(tag.description);
        })

        return formattedTags;
    }

}
