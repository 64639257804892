import DataLayerGenerator from "./dataLayerGenerator";

export default class SearcherDataLayerGenerator {
    constructor(searcher) {
        this.dataLayerGenerator = new DataLayerGenerator();
        this.searcher = searcher;
        this.searchType = [];
    }

    submitForm() {
        let todayDate = this._formatGaDate(new Date);
        let startDate = this._formatGaDate(this.searcher.getInputValue('start'));
        let endDate = this._formatGaDate(this.searcher.getInputValue('end'));
        let numChildren = parseInt(this.searcher.getInputValue('children'));
        let numAdults = parseInt(this.searcher.getInputValue('adults'));
        let numPeople = numChildren + numAdults;
        let destinations = this._getDestinations();

        this.dataLayerGenerator.generate({
            event: "search",
            event_detail_1: "submit",
            event_category: 'searcher',
            search_type: this.searchType.length > 0 ? this.searchType : 'allDestinations',
            destination_countries: destinations['country'],
            destination_zones: destinations['region'],
            destination_cities: destinations['city'],
            destination_coasts: destinations['coast'],
            destination_other: destinations['other'],
            date_today: todayDate,
            date_check_in: startDate,
            date_check_out: endDate,
            date_days_in_advance: this._compareDates(todayDate, startDate),
            num_nights: this._compareDates(startDate, endDate),
            num_rooms: this.searcher.getInputValue('rooms'),
            num_adults: numAdults,
            num_children: numChildren,
            num_guests: numPeople
        });
    }

    _getDestinations() {
        let optionsSelected = $('.destination-modal').find('.active-item:not([data-type="category"], [data-all-destinations="true"])');
        let destinationsInput = {
            "country": [],
            "region": [],
            "city": [],
            "coast": [],
            "other": []
        };

        if (optionsSelected.length > 0) {
            $.each(optionsSelected, function (i, destination) {
                let attribute = $(destination).attr('data-destination') ?? 'other';
                if (this.searchType.indexOf(attribute) === -1) {
                    this.searchType.push(attribute);
                }
                destinationsInput[attribute].push(destination.textContent?.trim());
            }.bind(this))
        }

        return destinationsInput;
    }

    _formatGaDate(date) {
        if (!date) {
            return '';
        }

        if (typeof(date) == 'object') {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            day = day < 10 ? '0' + day : day;
            month = month < 10 ? '0' + month : month;

            return '' + year + month + day;
        }

        let parts = date.split('-');
        return parts[2] + parts[1] + parts[0];
    }

    _compareDates(start, end){
        if (!start || !end) {
            return '';
        }

        let startDate = new Date(start.slice(0, 4), start.slice(4, 6) - 1, start.slice(6));
        let endDate = new Date(end.slice(0, 4), end.slice(4, 6) - 1, end.slice(6));

        let msDifference = endDate - startDate;
        let daysDifference = msDifference / (1000 * 60 * 60 * 24);

        daysDifference = Math.round(daysDifference);

        return daysDifference;
    }
}
