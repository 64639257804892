export default class FormValidator {
    constructor() {
        this.eventDelegation();
    };

    eventDelegation() {
        const form = $("form");

        form.on('change', '[data-fv-field]', (e) => {
            this.removePopovers(e.currentTarget);
        });

        form.on('change', '[data-form-group]', (e) => {
            const currentTarget = e.currentTarget;
            if (currentTarget.getAttribute("data-form-group-enabled")) {
                const currentForm = $(currentTarget).parents("form");
                const elementFormGroup = currentTarget.getAttribute("data-form-group");
                myApp.formGroupValidator.validateGroup(currentForm, elementFormGroup);
            }
        });

        form.on("submit", (e) => {
            const currentForm = e.currentTarget;
            $.each($("[data-form-group]"), (i, group) => {
                $(group).attr('data-form-group-enabled', 'true');
                myApp.formGroupValidator.validateGroup(currentForm, $(group).data("form-group"));
            });
            $('[data-input="surname2"]').addClass('input-success');
        });
    };

    getFeedbackIcons() {
        return {
            valid: 'ci ci-check', invalid: 'ci ci-cross', validating: 'ci ci-refresh', feedback: "form-control-feedback"
        };
    };

    getErrorType(errorType) {
        return {
            container: errorType
        };
    };

    getValidator(validatorType, minLength, maxLength) {
        let validator = {};
        switch (validatorType) {
            case 'notEmpty':
                validator = {
                    message: myApp.translator['user.login_page.login.validate.notEmpty']
                };
                break;
            case 'notSelected':
                validator = {
                    message: myApp.translator['user.login_page.login.validate.notSelected']
                };
                break;
            case 'emailAddress':
                validator = {
                    message: myApp.translator['user.login_page.login.validate.emailAddress']
                };
                break;
            case 'minLength':
                validator = {
                    min: minLength,
                    message: myApp.translator['user.login_page.login.validate.minLength'].replace('%%min%%', minLength)
                };
                break;
            case 'maxLength':
                validator = {
                    max: maxLength,
                    message: myApp.translator['user.login_page.login.validate.maxLength'].replace('%%max%%', maxLength)
                };
                break;
            case 'numeric':
                validator = {
                    message: myApp.translator['user.login_page.login.validate.numeric']
                };
                break;
            case 'identical':
                validator = {
                    message: myApp.translator['user.login_page.login.validate.identical']
                };
                break;
        }
        return validator;
    };

    disableSuccessAlerts(e, data) {
        const parent = data.element.parents('.form-group');
        parent.removeClass('has-success');
        parent.find('.has-success').removeClass('has-success');

        if (data.fv.getInvalidFields().length > 0) {
            data.fv.disableSubmitButtons(false);
        }
    };

    disableContinuousErrorValidation(e, data) {
        e.preventDefault();
        const invalidFields = data.fv.getInvalidFields();
        const firstInvalidField = invalidFields.eq(0);


        $.each(invalidFields, function (key, field) {
            if ($(field).is(firstInvalidField)) {
            } else {
                data.fv.resetField($(field));
            }
        });
    };

    disableEqualCalendarErrorsValidation(e, data) {
        e.preventDefault();
        const invalidFields = data.fv.getInvalidFields();
        let isSame = false;

        $.each(invalidFields, function (key, field) {
            if (field.id == 'dateValue') {
            } else if(field.classList.contains('children_age') && !isSame){
                isSame = true;
            } else {
                data.fv.resetField($(field));
            }
        });
    };



    displayErrorsOnPopover(e, data) {
        const fv = data.fv;
        const invalidField = fv.getInvalidFields().eq(0);
        const nameElement = $(invalidField).attr("name");
        const messageInvalidField = fv.getMessages(invalidField);

        $("small.help-block[data-fv-for='" + nameElement + "']").hide();
        $(invalidField).addClass('error');
        $(invalidField).attr('data-content', messageInvalidField);
        $(invalidField).popover('show');
    };

    displayErrorAsNotification(e, data) {
        toastr.remove();
        const fv = data.fv;
        const invalidField = fv.getInvalidFields().eq(0);
        const nameElement = $(invalidField).attr("name");
        const messageInvalidField = fv.getMessages(invalidField);

        $("small.help-block[data-fv-for='" + nameElement + "']").hide();
        myApp.notificationManager.showNotification('error', messageInvalidField);
    };

    removePopovers(elem) {
        $(elem).removeClass('error');
        $(".fv-form .popover").remove();
    };

    displayErrorsOnContainer(e, data, container, displayDefaultErrors, showErrorsContainer) {
        const messages = data.fv.getMessages(data.element);
        $(container).find('li[data-field="' + data.field + '"]').remove();
        for (let i in messages) {
            if ($(data.element).attr("data-label")) {
                this.createErrorFieldItemList(data.element, data.field, $(data.element).data("label"), container, showErrorsContainer);
            }
        }

        if (displayDefaultErrors) {
            data.element
                .data('fv.messages')
                .find('.help-block[data-fv-for="' + data.field + '"]')
                .hide();
        }
    };

    createErrorFieldItemList(element, field, label, container, showErrorsContainer) {
        const containerErrorTmp = [];
        $.each($(container + " a"), (i, el) => {
            if (containerErrorTmp.indexOf($(el).text()) === -1) {
                containerErrorTmp.push($(el).text());
            }
        });

        if (containerErrorTmp.indexOf(label) === -1) {
            $('<li/>')
                .attr('data-field', field)
                .wrapInner($('<a/>').attr('href', 'javascript: void(0);')
                    .addClass('btn btn-secondary simple')
                    .html(label)
                    .on('click', (e) => {
                        element.focus();
                        myApp.scrollManager.scrollToElement(element);
                    }))
                .appendTo(container + " ul");
            if (showErrorsContainer) {
                $(container).show();
            }
        }
    };

    getEmailValidatorRegExp() {
        return "^[a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*[.][a-zA-Z]{2,4}$";
    };

    getOnlyCharactersRegExp() {
        return "^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\\\\s_ -]*)$";
    };

    revalidateForm(form) {
        const formElements = $(form).data('formValidation').getOptions().fields;
        for (let field in formElements) {
            $(form).data('formValidation').revalidateField(field);
        }
    };

    validateFieldsOnFocusOut(form) {
        const formElement = $(form);

        if (formElement.length > 0) {
            const formElements = formElement.data('formValidation').getOptions().fields;
            for (let field in formElements) {
                const fieldToValidate = field;
                $('[data-fv-field="' + field + '"]').on('focusout countrychange', (e) => {
                    $(form).data('formValidation').revalidateField(fieldToValidate);

                    if (e.type === "countrychange") {
                        const currentTarget = e.currentTarget;
                        const label = document.querySelector('label[for=' + currentTarget.id + ']');
                        const inputDivider = document.querySelector('.vpt-input-divider');

                        if (label && inputDivider) {
                            inputDivider.style.setProperty("--divider-offset", `${parseInt(currentTarget.style.paddingLeft) - 5}px`);
                        }
                        if (label) {
                            label.style.left = currentTarget.style.paddingLeft;
                        }
                    }
                });
            }
        }
    }
}
