import SearcherDataLayerGenerator from "../trackers/dataLayer/searcherDataLayerGenerator";

export default class SearcherValidation {
    constructor(searcher,  searcherCache) {
        this.maxRoomPeople = 6;
        this.searcher = searcher;
        this.searcherCache = searcherCache;
        this.searcherDataLayerGenerator = new SearcherDataLayerGenerator(this.searcher);
        this.eventDelegation();
    }

    eventDelegation() {
        this.searcher.element.on('submit', (event) => {
            let validated = this.validateFields();
            if (!validated) {
                event.stopPropagation();
                event.stopImmediatePropagation();
                event.preventDefault();
            } else {
                this.searcherDataLayerGenerator.submitForm();
                this.searcherCache.setCacheValues();
                $('[data-entity="searcher-backdrop"]').show();
                $('[data-entity="searcher-backdrop-loader"]').show()
                $('[data-searcher="close"]').trigger( "click" );
            }
        });

        $('#booking-dates').on('change', (event) => {
            this.validateDates();
        });
    }

    validateFields() {
        let datesValidated = this.validateDates();
        let distributionValidated = this.validateDistribution();
        let validateDestinations = true;

        if (this.searcher.element.attr('action') === this.searcher.element.attr('data-any-type-action') ){
            validateDestinations = this.validateDestinationsWhenAnyDates();
        }

        if (myApp.deviceDetector.isMdScreen() || myApp.deviceDetector.isLgScreen()) {
            if (!datesValidated) {
                $('[data-input="booking-dates"]').click();
            } else if (!distributionValidated) {
                $('#dropdown-distributions').click();
            } else if (!validateDestinations) {
                $('#dropdown-destination').click();
            }
        }

        if (myApp.deviceDetector.isXsScreen()) {
            if (!validateDestinations) {
                $('#dropdown-destination').click();
            }
        }

        return datesValidated && distributionValidated && validateDestinations;
    }

    validateDistribution() {
        let validatedAdults = this.validateAdults();
        let validatedChildren = this.validateChildren();
        let validatedRooms = this.validateRooms();
        let validated = validatedAdults && validatedChildren && validatedRooms;

        if (validated) {
            $('#dropdown-distributions').removeClass('input-error');
        } else {
            $('#dropdown-distributions').addClass('input-error');
        }
        return validated;
    }

    validateAdults() {
        let numAdults = parseInt(this.searcher.getInputValue('adults'));
        return this.checkEmptyValue(numAdults) && numAdults > 0;
    }

    validateRooms() {
        let numRooms = parseInt(this.searcher.getInputValue('rooms'));
        let numPeople = parseInt(this.searcher.getInputValue('children')) + parseInt(this.searcher.getInputValue('adults'));

        let morePeopleThanRooms = this.validateMorePeopleThanRooms(numRooms, numPeople);
        let moreRoomsThanPeople = this.validateMoreRoomsThanPeople(numRooms, numPeople)

        return morePeopleThanRooms & moreRoomsThanPeople;
    }

    validateMoreRoomsThanPeople(numRooms, numPeople) {
        let notValidated = (numRooms && (numRooms > numPeople));
        if (notValidated) {
            $('[data-container="more-rooms-than-people-error-message"]').removeClass('hidden');
        } else {
            $('[data-container="more-rooms-than-people-error-message"]').addClass('hidden');
        }

        return !notValidated;
    }

    validateMorePeopleThanRooms(numRooms, numPeople) {
        let notValidated = (numRooms && (numPeople / numRooms > this.maxRoomPeople));
        if (notValidated) {
            $('[data-container="more-people-than-rooms-error-message"]').removeClass('hidden');
        } else {
            $('[data-container="more-people-than-rooms-error-message"]').addClass('hidden');
        }

        return !notValidated;
    }

    validateChildren() {
        let numChildren = parseInt(this.searcher.getInputValue('children'));
        let childrenAges = this.searcher.getInputValue('childrenAges');
        let childrenAgesArray = childrenAges.split(';');

        let childrenAgesArrayFiltered = childrenAgesArray.filter((a) => a);

        if (childrenAgesArrayFiltered.length === numChildren) {
            $('[data-container="children-ages-error-message"]').addClass('hidden');
        } else {
            $('[data-container="children-ages-error-message"]').removeClass('hidden');
        }

        this.searcher.element.find('[data-input="children-age"]').each((index, element) => {
            let currentElement = $(element);
            if (currentElement.val() >= 0) {
                currentElement.removeClass('input-error');
            } else {
                currentElement.addClass('input-error');
            }
        });
        return childrenAgesArrayFiltered.length === numChildren;
    }

    validateDates() {
        let start = this.searcher.getInputValue('start');
        let end = this.searcher.getInputValue('end');
        let isAnyDate = false;

        if (this.searcher.element.attr('action') === this.searcher.element.attr('data-any-type-action') ){
            isAnyDate = true;
        }

        let validated = (this.checkEmptyValue(start) && this.checkEmptyValue(end)) || isAnyDate;
        if (validated) {
            $('[data-input="booking-dates"]').removeClass('input-error');
            $('[data-container="dates-error-message"]').addClass('hidden');
        } else {
            $('[data-input="booking-dates"]').addClass('input-error')
            $('[data-container="dates-error-message"]').removeClass('hidden');
        }
        return validated;
    }

    checkEmptyValue(value) {
        return value !== null && value !== "";
    }

    validateDestinationsWhenAnyDates() {

        return $('.destination-input').length > 0;

    }
}
