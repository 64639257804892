import '../genericController';
import BannerDownloadApp from "@web/vpt-assets/assets/js/modules/bannerDownloadApp";
import UserExclusiveService from "../../services/user/userExclusiveService";
import RedirectLanguage from "../../services/redirectLanguage";
import CholloManager from "../../services/entity/cholloManager";
import GroupVideoManager from "../../services/group/GroupVideoManager";
import * as Swiper from 'swiper';

$(function () {
    new BannerDownloadApp();
    new UserExclusiveService();
    new RedirectLanguage();
    new CholloManager();
    new GroupVideoManager();

    let bannerElement = $('#banner-download-app');
    if (bannerElement.length > 0 && !bannerElement.hasClass('hidden')) {
        $('body').addClass('contains-app-banner');
        $('[data-action="close-banner-app"]').on('click', () => {
            $('body').removeClass('contains-app-banner');
            myApp.scrollManager.lastScrollTop = -40;
            myApp.scrollManager.checkScrollUp();
        });
    }

    $(document).ready(function () {
        let sections = Object.values(document.querySelectorAll('section[data-category]')).map((section) => {
            return section.dataset.category;
        });
        let urlParams = new URLSearchParams(document.location.search);

        for (const section of sections) {
            if (urlParams.has(section)) {
                let headerHeight = document.querySelector('[data-entity="mobile-navbar-menu"]').offsetHeight;
                let offset = (myApp.deviceDetector.isXsScreen() || myApp.deviceDetector.isSmScreen() ? headerHeight : 0) + 10;
                window.scrollTo({
                    top: window.scrollY + document.querySelector('[data-category="' + section +'"]').getBoundingClientRect().top - offset,
                    behavior: 'smooth',
                })
                break;
            }
        }

        document.querySelectorAll('list-external-reviews').forEach(e => e.initializeSlider(Swiper));
    });
});
