export default class DateFormatter {
    getShortMonthName(date, language) {
        const options = {
            month: "short"
        };
        return date.toLocaleDateString(language, options);
    }

    getLongMonthName(date, language) {
        const options = {
            month: "long"
        };
        return date.toLocaleDateString(language, options);
    };

    getShortDate(date, separator = '/', excludeDays = false) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        if (excludeDays) {
            return year + separator + month;
        } else {
            return year + separator + month + separator + day;
        }

    }

    getShortDateByDay(date, separator = '/', excludeDays = false) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        if (excludeDays) {
            return month + separator + year;
        } else {
            return day + separator + month + separator + year;
        }
    }

    getDateFromDayMonthYear(dateString) {
        const dateParts = dateString.split(/\/|-/);
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    }
};
