import AjaxManager from "../ajaxManager";
import LazyLoadManager from "../lazyLoadManager";
import CustomMap from "../../entity/customMap/customMap";
import CustomMapBuilder from "../customMap/customMapBuilder";
import SliderManager from "../slider/sliderManager";

export default class CholloManager {

    constructor() {
        this.ajaxManager = new AjaxManager();
        this.customMapBuilder = new CustomMapBuilder();
        this.groupId = null;
        this.eventDelegation();
    }

    eventDelegation() {
        const body = $("body");

        body.on("click", '.acceptAlert', () => {
            const groupId = this.groupId;
            if (groupId) {
                this.notifyAvailableChollo(groupId);
            }
            myApp.customModalManager.closeModal('#confirmAlert');
        });

        body.on("click", '.declineAlert', () => {
            myApp.customModalManager.closeModal('#confirmAlert');
        });

        body.on("click", '[custom-modal="#confirmAlert"]', (event) => {
            this.groupId = event.currentTarget.dataset.id;
        });

        body.on("click", '[data-action="open-modal"]', (event) => {
            const currentTarget = $(event.currentTarget);
            const idUser = $('.id_user').val();
            const groupId = currentTarget.data('id');
            const dateId = currentTarget.data('date-id');
            const showButton = currentTarget.data('show-button');
            const adults = currentTarget.data('adults') ? currentTarget.data('adults') : parseInt($('[data-input-sibling="adults"]').val());
            const children = currentTarget.data('children') ? currentTarget.data('children') : parseInt($('[data-input-sibling="children"]').val());
            this.showInfoChollo(groupId, idUser, dateId, adults, children, currentTarget, showButton);
        });

        const isApp = this.getSafe(() => myApp.config.parameters.isApp, false);
        if (!isApp) {
            if (!window.name) {
                window.name = '_' + Math.random().toString(36).substr(2);
            }

            window.addEventListener('beforeunload', () => {
                let date = new Date();
                date.setDate(date.getDate() + 1);
                document.cookie = `tab_id=${window.name}; path=/; expires=${date.toUTCString()}`;
            });
        }
        let loginFavourites = document.querySelectorAll('[data-favourite-icon][data-open="login-user"]');
        if (loginFavourites) {
            loginFavourites.forEach((loginFavourite) => {
                loginFavourite.addEventListener('click', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                });
            })

        }
    }

    getSafe(fn, defaultVal) {
        try {
            return fn();
        } catch (e) {
            return defaultVal;
        }
    }

    notifyAvailableChollo(groupId) {
        $.ajax({
            type: 'POST',
            url: myApp.config.routing.notifyAvailabileChollo,
            data: {
                groupId: groupId
            },
            success: function (response) {
                const notificationButton = $("[data-id=" + groupId + "].coming-soon-alert");
                if (notificationButton.length > 0) {
                    let icon = notificationButton.find('i');

                    notificationButton.removeAttr("data-action");
                    notificationButton.removeAttr("data-id");
                    notificationButton.removeAttr("data-id-user");
                    notificationButton.addClass("active");
                    notificationButton.html(response.buttonText);
                    notificationButton.prepend(icon);

                    let iconText = document.querySelector('#coming-soon-alert [slot="header-content"]');
                    iconText.textContent = response.title;
                    let infoText = document.querySelector('#coming-soon-alert [slot="body-content"]');
                    infoText.textContent = response.text;
                    document.querySelector('#coming-soon-alert').openCustomNotification();

                    if (myApp.deviceDetector.isXsScreen() || myApp.deviceDetector.isSmScreen()) {
                        $('[data-coming-soon-button="content"]').animate({bottom: '-20%'}, 500);
                    }
                }

                const comingSoonContent = $('[data-coming-soon-button="content"]');
                if (comingSoonContent.length > 0) {
                    comingSoonContent.find('[data-coming-soon-button="button"]').removeClass('coming-soon-button')
                        .addClass('coming-soon-button-disabled')
                        .removeAttr('data-action');
                    comingSoonContent.find('[data-coming-soon-button="text"]').text(response.title).removeClass('bold');

                    if (myApp.deviceDetector.isXsScreen() || myApp.deviceDetector.isSmScreen()) {
                        comingSoonContent.animate({bottom: '0'}, 500);
                    }
                }
            }
        });
    }

    showInfoChollo(groupId, idUser, dateId, adults, children, elem, showButton) {
        switch (elem.data('open')) {
            case 'info-chollo':
                var link = elem.data("link");
                var data = {
                    id: groupId,
                    idUsuario: idUser,
                    esModal: 1,
                    data: dateId,
                    persones: adults,
                    num_nens: children,
                    showButton: showButton
                };
                if (link !== this.currentLink) {
                    let type = 'POST';
                    this.currentLink = link;
                    let callback = function callback(response) {
                        $("#moreInfoModalHeader-m").html(response.header);
                        $("#moreInfoModalContent-m").html(response.body);
                        $("#moreInfoModalFooter-m").html(response.footer);
                        $('#groupHotel [data-entity="modal-body"]').html(response.infoHotel);
                        myApp.groupCardManager.showMapModalListener()

                        for (const activity in response.infoActivity) {
                            $('#groupActivity [data-entity="modal-body"]').append(response.infoActivity[activity]);
                        }

                        let showReviewsModule =  document.querySelector('[data-action="showReviewsModule"]');
                        if (showReviewsModule) {
                            document.querySelector('[data-action="showReviewsModule"]').addEventListener("click", () => {
                                let reviewsContainer = '[data-entity="reviews"]';
                                if (document.querySelector(reviewsContainer)) {
                                    myApp.scrollManager.scrollContainerToElement(
                                        '[id="info_chollo_modal"] .custom-modal-body',
                                        reviewsContainer
                                    );
                                }
                            });
                        }

                        const itineraryModal = document.querySelector('#itineraryModal');
                        if (!itineraryModal || !response.infoItinerary) return;
                        itineraryModal.querySelector('[data-entity="modal-body"]').innerHTML = response.infoItinerary;
                        itineraryModal.querySelector('[data-entity="custom-modal-title"]').innerHTML = response.itineraryTitle;
                        const shareItineraryContainer = document.createElement('div');
                        shareItineraryContainer.setAttribute('data-entity', 'share-itinerary-container');
                        shareItineraryContainer.innerHTML = response.shareItinerary;
                        itineraryModal.insertAdjacentHTML('afterend', shareItineraryContainer.outerHTML);
                        myApp.customModalManager.reinitialize('[data-entity="itinerary-modal"]');
                        myApp.customModalManager.reinitialize('[data-entity="share-itinerary-container"]');
                    };
                    this.ajaxManager.ajaxCall(type, link, data, callback);
                }
                myApp.customModalManager.closeModal('.custom-modal');
                myApp.customModalManager.openModal('#info_chollo_modal');
                break;
            case 'info-hotel':
                var link = elem.data("link");
                var data = {
                    id: groupId,
                    idUsuario: idUser,
                    esModal: 1,
                    data: dateId,
                    persones: adults,
                    num_nens: children,
                    showButton: showButton
                };
                if (link !== this.currentLink) {
                    let type = 'POST';
                    this.currentLink = link;
                    let callback = function callback(response) {
                        if (response.isModalHotel) {
                            $('#groupHotel [data-entity="modal-body"]').html(response.infoHotel);
                            document.querySelector('[name="hotel-' + response.idHotel + '"] .active-visible').classList.remove('active-visible')
                            myApp.groupCardManager.showMapModalListener();

                            let sliderManager = new SliderManager();
                            sliderManager.addSlider('[data-gallery="hotel-' + response.idHotel + '"]', null);

                            document.querySelector('[data-action="scroll-to-hotel-reviews"]').addEventListener("click", () => {
                                let reviewsContainer = '[id="groupHotel"] [name="hotel-' + response.idHotel + '"] [data-entity="reviews"]';
                                if (document.querySelector(reviewsContainer)) {
                                    myApp.scrollManager.scrollContainerToElement(
                                        '[id="groupHotel"] .custom-modal-body',
                                        reviewsContainer
                                    );
                                }
                            });
                        }
                    };
                    this.ajaxManager.ajaxCall(type, link, data, callback.bind(this));
                }

                myApp.customModalManager.closeModal('#info_chollo_modal');
                myApp.customModalManager.openModal('#groupHotel');
                break;
        }
    }
}
