import CounterHandler from "../counterHandler";
import SearcherManager from "./searcherManager";

export default class SearcherDistribution {
    constructor(searcher, searcherValidation) {
        this.searcher = searcher;
        this.searcherValidation = searcherValidation;
        new CounterHandler();
        this.eventDelegation();
    }

    eventDelegation() {
        $('[data-dropdown-menu="distributions"]').on('click', (event) => {
            let currentTarget = $(event.target);
            let dropdownButton;
            let closestDropdownButton = currentTarget.closest('[data-dropdown-button="true"]');
            if (currentTarget.data('dropdown-button') === true) {
                dropdownButton = currentTarget;
            }
            if (closestDropdownButton.length > 0) {
                dropdownButton = closestDropdownButton;
            }
            if (!dropdownButton || (dropdownButton && dropdownButton.data('validate') === true)) {
                event.stopPropagation();
                event.stopImmediatePropagation();
                event.preventDefault();
                if (dropdownButton && this.searcherValidation.validateDistribution()) {
                    $('[data-dropdown-menu="distributions"]').dropdown('toggle');
                }
            }
        });

        $('[name="' + SearcherManager.inputs.rooms + '"]').on('change', (event) => {
            this.setRooms($(event.currentTarget).val())
            this.searcherValidation.validateDistribution();
        });

        $('[data-counter="adults"]').on('change', (event, value) => {
            this.setAdults(value);
            this.searcherValidation.validateDistribution();
        });

        $('[data-counter="children"]').on('change', (event, value) => {
            this.setChildren(value)
            this.searcherValidation.validateDistribution();
        });

        $('body').on('change', '[data-input="children-age"]', () => {
            this.onChangeChildrenAge();
            this.searcherValidation.validateDistribution();
        });
    }

     setDistributionText(adults, children, rooms) {
        let adultsText, childrenText, roomsText;

        adultsText = (adults == 1) ? myApp.translator['book.info.adult'][0] : myApp.translator['book.info.adult'][1];
        childrenText = (children == 1) ? myApp.translator['book.info.child'][0] : myApp.translator['book.info.child'][1];
        roomsText = (rooms == 1) ? myApp.translator['book.info.room'][0] : myApp.translator['book.info.room'][1];

        let text = adults + ' ' + adultsText;
        if (children > 0) {
            text = text + ', ' + children + ' ' + childrenText;
        }

        if (rooms > 0) {
            text = text + ', ' + rooms + ' ' + roomsText;
        }

        $('[data-text="distribution"]').text(text);
    }

    setRooms(value) {
        this.setDistributionText(
            this.searcher.getInputValue('adults'),
            this.searcher.getInputValue('children'),
            value
        );
    }

    setChildren(value) {
        this.searcher.setInputValue('children', value);
        this.setDistributionText(
            this.searcher.getInputValue('adults'),
            value,
            this.searcher.getInputValue('rooms'),
        );

        const childrenDecrease = document.querySelector('[data-counter="children"] [data-counter-action="decrease"]');
        const childrenIncrease = document.querySelector('[data-counter="children"] [data-counter-action="increase"]');
        if (!childrenDecrease.classList.contains('active') && value > this.searcher.minChildrenLimit) {
            childrenDecrease.classList.add('active');
        }
        if (childrenIncrease.classList.contains('active') && value == this.searcher.maxChildrenLimit) {
            childrenIncrease.classList.remove('active');
        }

        if (value >= this.searcher.maxChildrenLimit) {
            $('[data-container="max-children-error-message"]').show();
        } else {
            $('[data-container="max-children-error-message"]').hide();
        }

        this.checkChildrenAgesContainer(value);
        this.onChangeChildrenAge();
    }

    setAdults(value) {
        this.searcher.setInputValue('adults', value);
        let numRooms = this.searcher.getInputValue('rooms');
        this.setDistributionText(
            value,
            this.searcher.getInputValue('children'),
            numRooms,
        );

        const adultsDecrease = document.querySelector('[data-counter="adults"] [data-counter-action="decrease"]');
        const adultsIncrease = document.querySelector('[data-counter="adults"] [data-counter-action="increase"]');
        if (adultsDecrease.classList.contains('active') && value == this.searcher.minAdultsLimit) {
            adultsDecrease.classList.remove('active');
        }
        if (adultsIncrease.classList.contains('active') && value == this.searcher.maxAdultsLimit) {
            adultsIncrease.classList.remove('active');
        }

        if (value >= this.searcher.maxAdultsLimit) {
            $('[data-container="max-adults-error-message"]').removeClass('hidden');
        } else {
            $('[data-container="max-adults-error-message"]').addClass('hidden');
        }
    }

    onChangeChildrenAge() {
        let childrenAges = [];
        let childrenAge;
        $('[data-input="children-age"]').each((index, element) => {
            childrenAge = parseInt($(element).val());
            if (Number.isInteger(childrenAge)) {
                childrenAges.push(childrenAge);
            }
        });
        this.searcher.setInputValue('childrenAges', childrenAges.join(';'));
    }

    checkChildrenAgesContainer(value) {
        let childrenAgeTemplate = $('#children-age-selector');
        let childrenAgesContainer = $('[data-container="children-ages"]');
        let childrenAgeContainers = $('[data-container="children-ages"] [data-container="children-age"]');
        let childrenAge;
        let childrenAgeContainersLength = childrenAgeContainers.length;
        if (value == 0) {
            childrenAgesContainer.hide();
        } else {
            childrenAgesContainer.show();
        }
        if (value > childrenAgeContainersLength) {
            for (let i = childrenAgeContainersLength; i < value; i++) {
                childrenAge = childrenAgeTemplate.clone(true);
                childrenAgesContainer.append(childrenAge.html());
            }
        } else {
            for (let i = value; i <= childrenAgeContainersLength; i++) {
                childrenAgeContainers.last().remove();
            }
        }
    }

    setDistributions(adults, children, childrenAges, rooms) {
        if (adults && !isNaN(parseInt(adults))) {
            $('[data-counter-type=adults]').text(adults);
            $('[data-counter-type=adults]').attr('data-counter-value', adults);
            this.setAdults(adults);
        }
        if (childrenAges !== '') {
            let numChildren =  childrenAges.split(';').length;

            children = parseInt(children);
            if (numChildren !== children) {
                if (children === 0) {
                    if (numChildren === 0) {
                        children = 'NaN'
                    } else {
                        children = numChildren;
                    }
                }
            }
        }

        if (children && !isNaN(children)) {
            $('[data-counter-type=children]').text(children);
            $('[data-counter-type=children]').attr('data-counter-value', children);
            this.setChildren(children)
            if (childrenAges) {
                this.setCachedAgesForChildren(childrenAges);
            }
            this.searcherValidation.validateDistribution();
        }

        if (rooms) {
            $('[name=' + SearcherManager.inputs.rooms + ']').find("option[value=" + rooms + "]").prop('selected', true);
        }
    }

    setCachedAgesForChildren(childrenAges) {
        $('[data-counter=children]').find('[data-counter-action=decrease]').removeClass('disabled');
        let childrenAgesArray = childrenAges.split(";");
        $('[data-input=children-age]').each(function(key) {
            $(this).find("option[value="+childrenAgesArray[key]+"]").attr('selected', true);
        });
        this.searcher.setInputValue('childrenAges', childrenAges);
    }
}
