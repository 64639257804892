import CustomMarker from "./customMarker";

export default class CustomMap
{
    constructor(id, latitude, longitude, zoom)
    {
        this.id = id;
        this.latitude = latitude;
        this.longitude = longitude;
        this.zoom = zoom;
        this.arrayMarkers = [];
        this.bounds = [];
        this.defaultTileProvider = 'OpenStreetMap.Mapnik';
        this.markerClusterGroup = L.markerClusterGroup();
        this.createMap()
    }

    createMap()
    {
        this.map = L.map(this.id,
            {
                preferCanvas: true,
                maxBounds: L.latLngBounds(L.latLng(-85, -180), L.latLng(85, 180)),
                minZoom: 2,
            }
        ).setView([this.latitude, this.longitude], this.zoom);

        L.tileLayer.provider(this.defaultTileProvider).addTo(this.map);
    }

    addMarker(marker)
    {
        this.arrayMarkers.push(marker);
        this.markerClusterGroup.addLayer(marker.marker);
        this.bounds.push([marker.latitude, marker.longitude]);
    }

    getMarker(markerId)
    {
        for (let marker of this.arrayMarkers) {
            if (marker.id === markerId) {
                return marker;
            }
        }
    }

    addLayer()
    {
        this.map.addLayer(this.markerClusterGroup);
    }

    fitBounds(markerList)
    {
        if (markerList.length > 0) {
            this.map.invalidateSize();
            this.map.fitBounds(markerList.map(marker => [marker.latitude, marker.longitude]));
        } else {
            this.map.fitBounds(this.bounds, {
                maxZoom: this.zoom
            });
        }
    }

    openPopupByMarker(markerId)
    {
        const marker = this.getMarker(markerId);
        if (marker instanceof CustomMarker) {
            marker.openPopup();
        }
    }

    smoothMarkerZoom(markerId)
    {
        const marker = this.getMarker(markerId);
        if (marker instanceof CustomMarker) {
            if (this.map.getZoom() == 10) {
                this.flyTo(L.latLng(parseFloat(marker.latitude), parseFloat(marker.longitude)), () => {
                    marker.openPopup();
                });
            }
            this.map.setView(L.latLng(parseFloat(marker.latitude), parseFloat(marker.longitude)), 10).once("moveend zoomend", () => {
                this.flyTo(L.latLng(parseFloat(marker.latitude), parseFloat(marker.longitude)), () => {
                    marker.openPopup();
                });
            });

        }
    }

    flyTo(latLng, callback)
    {
        this.map.flyTo(latLng, 17, {
            animate: true,
            duration: 1.5
        }).once("moveend zoomend", callback);
    }

    invalidateSize() {
        this.map.invalidateSize();
    }
}
